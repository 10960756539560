import React from 'react';

export const BonadocsEditorProjectsCreation: React.FC = () => {
    // Your component logic here

    return (
        <div>
            creation
        </div>
    );
};