import React from "react";

export const BonadocsEditorViewVariablesTableHeader: React.FC = () => {
  return (
    // Your component JSX code here
    <div className="bonadocs__editor__variables__table__header">
      <h1>Name</h1>
      <h1>Value</h1>
      <div className="bonadocs__editor__variables__table__header__menu">
        <h1 className="ma-auto">Action</h1>
      </div>
    </div>
  );
};
